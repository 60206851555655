<template>
	<div class="app-container">
		<el-tabs
			v-model="activeTab"
			v-loading="loading"
		>
				<el-tab-pane label="User Status Manager" name="userList">
					<br />
					<el-row :gutter="20">
						<el-col :span="3">
							<el-select
								v-model="currentStatusOption"
								placeholder="Select Status"
							>
								<el-option
									v-for="item in statusOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								>
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="2">
							<el-button
								type="primary"
								icon="el-icon-search"
								@click="searchUserStatus()"
								>Search</el-button
							>
						</el-col>
					</el-row>

					<br />
					<div v-if="wasClickSearch">
						
							<div>Total User: {{ totalUserProfile }}</div>
							<br />
							<div>
								<button
									@click="changeTab(currentPage - 1)"
									:disabled="currentPage <= 1"
								>
									Previous
								</button>
								[{{ currentPage }} / {{ totalPages }}]
								<button
									@click="changeTab(currentPage + 1)"
									:disabled="currentPage >= totalPages"
								>
									Next
								</button>
							</div>

							<el-row :gutter="20">
								
								<el-col>
									<el-card class="box-card" style="min-height: 800px">
										<el-table
											:data="this.statusUserList"
											border
											max-height="800px"
											highlight-current-row
											@current-change="handleCurrentTableChange"
											style="width: 100%">
											<el-table-column label="Index" type="index" width="60" />
											<el-table-column 
												prop="id"
												label="User ID"
												width="150">
											</el-table-column>

											<el-table-column
												prop="name"
												label="User Name"
												width="200">
											</el-table-column>

											<el-table-column
												prop="accountPower"
												label="Account Power"
												width="200">
											</el-table-column>

											<el-table-column
												prop="profileClan.clanID"
												label="Clan ID">
											</el-table-column>

											<el-table-column
												prop="statusName"
												label="Current Status">
											</el-table-column>
										</el-table>
									</el-card>
								</el-col>

								<el-col>
									<div v-if="wasSelectUser">
										<el-collapse v-model="activeCollapseName">
											<el-collapse-item name="userInfo">
												<template slot="title">
													<h2>User Information</h2>
												</template>
												<div>
													<json-viewer :value="currentUserInfo"></json-viewer>
													<!-- <pre>{{JSON.stringify(currentUserInfo, null, 2)}}</pre> -->
												</div>
												<!-- <el-card class="box-card" style="min-height: 750px">
													<el-form
														label-width="200px"
														:model="currentUserInfo"
													>
														<el-form-item label="ID:">
															<el-input
																v-model="currentUserInfo.id"
																style="width: 300px"
																disabled
															>
															</el-input>
														</el-form-item>
														<el-form-item label="Name:">
															<el-input
																v-model="currentUserInfo.name"
																style="width: 300px"
																disabled
															>
															</el-input>
														</el-form-item>

														<el-form-item label="Base Power:">
															<el-input
																v-model="currentUserInfo.accountPower"
																style="width: 300px"
																disabled
															>
															</el-input>
														</el-form-item>

														<el-form-item label="Max Endless:">
															<el-input
																v-model="currentUserInfo.maxEndlessArea"
																style="width: 300px"
																disabled
															>
															</el-input>
														</el-form-item>

														<el-form-item label="Max RaidBoss:">
															<el-input
																v-model="currentUserInfo.maxRaidBossPoint"
																style="width: 300px"
																disabled
															>
															</el-input>
														</el-form-item>

														<el-form-item label="Max LDB Cup:">
															<el-input
																v-model="currentUserInfo.maxLeaderBoardCup"
																style="width: 300px"
																disabled
															>
															</el-input>
														</el-form-item>

														<el-form-item label="Set User Status">
															<el-select
																v-model="currentUserInfo.userStatus"
																placeholder="Select Status"
															>
																<el-option
																	v-for="item in statusOptions"
																	:key="item.value"
																	:label="item.label"
																	:value="item.value"
																	disabled
																>
																</el-option>
															</el-select>
														</el-form-item>
													</el-form>
													<br />
													
												</el-card> -->
												<el-button type="primary" @click="setStatusNormal()" :disabled="currentUserInfo.userStatus == 1">
														Set Normal
													</el-button>
													<el-button type="primary" @click="setStatusCheater()" :disabled="lastSelectedUserInfo.userStatus == 3">
														Set Cheater
												</el-button>
											</el-collapse-item>
										</el-collapse>
									</div>
								</el-col>

							</el-row>
					</div>
				</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
import apiMtoUserProfile from "@/api/mtoUserProfile.js";
import clan from "@/api/clan.js";
import { SERVER_RESPONSE_CODE, USER_STATUS, CLAN_CODE } from "@/defines/constants.js";
export default {
	name: "ClanInformation",
	data() {
		return {
			loading: false,
			activeCollapseName: ["userInfo"],
			wasClickSearch: false,
			wasSelectUser: false,
			activeTab: "userList",

			totalRows: 100,
			currentPage: 1,
			totalPages: 1,
			totalUserProfile: 0,

			currentUserInfo: {},
			lastSelectedUserInfo: {},

			currentStatusOption: "",
			newStatusOption: "",
			statusUserList: [],
			statusOptions: [
				{
					value: 0,
					label: "UNKNOWN",
				},
				{
					value: 1,
					label: "NORMAL",
				},
				{
					value: 2,
					label: "CHUOI_WARNING",
				},
				{
					value: 3,
					label: "BLACK_LIST",
				},
			],
		};
	},
	created() {
		this.loading = false;
		this.wasClickSearch = false;

		this.totalRows = 10;
		this.currentPage = 1;
		this.totalPages = 1;
		this.totalUserProfile = 0;
		this.statusUserList = [];
		this.currentUserInfo = {};
		this.lastSelectedUserInfo = {};
	},
	methods: {
		
		// ========================================
		async searchUserStatus() {

			if (this.currentStatusOption.length <= 0 || this.currentStatusOption == 0) {
				Message({
					message: `Please select "Search" Options`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			this.wasSelectUser = false;
			this.statusUserList = [];
			let responseData = undefined;
			this.loading = true;		
			responseData = await apiMtoUserProfile.getUserProfileListByPage(this.currentStatusOption, this.totalRows, this.currentPage);
			this.loading = false;
			if (responseData != undefined) {
				if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
					this.wasClickSearch = true;
					this.statusUserList = responseData.data.userList;
					this.totalUserProfile = responseData.data.totalUser;
					this.totalPages = Math.floor(responseData.data.totalUser / this.totalRows) + 1 ;
					Message({
						message: `User founds: ${this.totalUserProfile}`,
						type: "success",
						duration: 5 * 1000,
					});

					this.statusUserList.forEach((user) => {
						if (user.userStatus === null || user.userStatus === undefined) {
							user.userStatus = USER_STATUS.UNKNOWN;
						}

						user.statusName = this.getKeyByValue(
							USER_STATUS,
							user.userStatus
						);
					});

					console.log(this.statusUserList);
				} else {
					console.log(responseData);
				}
			}
		},

		// ========================================
		async setStatusNormal() {
			if (this.currentUserInfo.id === undefined) {
				Message({
					message: `Select User!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			try {
				this.loading = true;
				let returnData = await apiMtoUserProfile.requestUpdateUserProfileStatus(this.currentUserInfo.id, USER_STATUS.NORMAL);
				this.loading = false;
				this.wasSelectUser = false;
				if (returnData !== null &&
					returnData !== undefined &&
					returnData.code !== null &&
					returnData.code !== undefined &&
					returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {
					Message({
						message: "Change Status User To Normal success!",
						type: "success",
						duration: 5 * 1000,
					});

					let userNewStatus = returnData.data;					
					for( var i = 0; i < this.statusUserList.length; i++){ 
						if ( this.statusUserList[i].id === userNewStatus.id) {
							this.statusUserList.splice(i, 1);
							break;
						}
					}
				} else {
					Message({
						message: returnData.message,
						type: "error",
						duration: 5 * 1000,
					});
				}
			}
			catch (error) {
				this.loading = false;
				console.log(error);
				Message({
					message: error.message,
					type: "error",
					duration: 5 * 1000,
				});
			}
		},

		// ========================================
		async setStatusCheater() {
			if (this.currentUserInfo.id === undefined) {
				Message({
					message: `Select User!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			try {
				this.loading = true;
				let returnData = await apiMtoUserProfile.requestUpdateUserProfileStatus(this.currentUserInfo.id, USER_STATUS.BLACK_LIST);
				this.loading = false;
				this.wasSelectUser = false;
				if (returnData !== null &&
					returnData !== undefined &&
					returnData.code !== null &&
					returnData.code !== undefined &&
					returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {
					Message({
						message: "Change Status User To Normal success!",
						type: "success",
						duration: 5 * 1000,
					});

					let userNewStatus = returnData.data;					
					for( var i = 0; i < this.statusUserList.length; i++){ 
						if ( this.statusUserList[i].id === userNewStatus.id) {
							this.statusUserList.splice(i, 1);
							break;
						}
					}

					// kick member leave clan
					if(userNewStatus.profileClan != null && userNewStatus.profileClan !== undefined) {
						let clanID = userNewStatus.profileClan.clanID;						
						let returnKickData = await clan.requestKickMemberCheat(clanID, userNewStatus.id);
						if (returnKickData !== null &&
							returnKickData !== undefined &&
							returnKickData.code !== null &&
							returnKickData.code !== undefined &&
							returnKickData.code == CLAN_CODE.CLAN_ERROR_WAS_KICK_FROM_CLAN) {
							Message({
								message: "Kick user leave clan success!",
								type: "success",
								duration: 5 * 1000,
							});
						}
					}

				} else {
					Message({
						message: returnData.message,
						type: "error",
						duration: 5 * 1000,
					});
				}
			}
			catch (error) {
				this.loading = false;
				console.log(error);
				Message({
					message: error.message,
					type: "error",
					duration: 5 * 1000,
				});
			}
		},

		// ========================================
		// FUNCTION 
		// ========================================
		getKeyByValue(object, value) {
			return Object.keys(object).find((key) => object[key] === value);
		},

		// ========================================
		handleCurrentTableChange(val) {
			
			this.currentUserInfo = { ...val };
			this.lastSelectedUserInfo = { ...val };
			if(val != null) {
				this.wasSelectUser = true;
			}
		},

		// ========================================
		async changeTab(currentPageShow) {
			this.loading = true;

			this.currentPage = currentPageShow;
			this.statusUserList = [];
			let responseData = undefined;	
			responseData = await apiMtoUserProfile.getUserProfileListByPage(this.currentStatusOption, this.totalRows, this.currentPage);		
			this.loading = false;

			if (responseData != undefined) {
				if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
					this.statusUserList = responseData.data.userList;
					this.totalPages = Math.floor(responseData.data.totalUser / this.totalRows) + 1 ;
					Message({
						message: `User founds: ${this.statusUserList.length}`,
						type: "success",
						duration: 5 * 1000,
					});

					// this.statusUserList.forEach((user) => {
					// 	if (user.userStatus === null || user.userStatus === undefined) {
					// 		user.userStatus = USER_STATUS.UNKNOWN;
					// 	}

					// 	user.statusName = this.getKeyByValue(
					// 		USER_STATUS,
					// 		user.status
					// 	);
					// });

					console.log(this.statusUserList);
				} else {
					console.log(responseData);
				}
			}
		},		
    }
};
</script>