import request from '@/utils/request-service-admin.js'
import requestAdmin from "@/utils/request-service-admin";

const mtoUserProfile = {
    // ========================================
    getUserProfileListByPage: async (userStatus, totalRows, pageIndex) => {
        return await request({
            url: '/admintool/userProfile/get-user-profile-list',
            method: 'post',
            data: {
                userStatus: userStatus,
                totalRows: totalRows,
                pageIndex: pageIndex
            }
        })
    },

    // ========================================
	requestUpdateUserProfileStatus: async (userID, userStatus) => {
        return await request({
            url: '/admintool/userProfile/update-user-profile-status',
            method: 'post',
            data: {
                id: userID,
                userStatus: userStatus
            }
        })
	},

    getUserProfile: (userId) => {
        return request({
            url: `/admintool/userProfile/get-user-profile/${userId}`,
            method: `get`,
        });
    },

    getArrayUserProfile : async (arrayId, arrayField) => {
        return request({
            url: '/admintool/userProfile/get-array-user-profile',
            method: 'post',
            data: {
                ArrayId: arrayId,
                ArrayField: arrayField
            }
        });
    }
}

export default mtoUserProfile;